import React, { Component } from 'react';
import loadable from '@loadable/component';

const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);
const EmailForm = loadable(() => import('../components/sub-components/emailForm'));
const MailingListForm = loadable(() => import('../components/sub-components/mailingListForm'));

class Exhibitions extends Component {
  render() {
    return (
      <InfoPage
        title="Jennifer Mone Hill Artist - Contact"
        description="How to get in touch with Jennifer Mone Hill Artist. Subscribe to our mailing list here to get artist updates."
        keywords={[
          'contact',
          'Jennifer Moné Hill',
          'Jen Hill Artist',
          'Jennifer Mone Hill',
          'Jen Hill',
          'Jen Hill Artist',
          'email',
          'mailing list',
          'phone',
        ]}
        imageLink="/works/Spectrum"
        imgSrc="Spectrum-by-Jennifer-Mone-Hill-800.jpg"
        imgSrc2x="Spectrum-by-Jennifer-Mone-Hill-1600.jpg"
        alt="Spectrum by Jennifer Moné Hill"
        customImageWidth={30}
        roomyLayout
      >
        <ContentBox>
          <h2>Join our mailing list</h2>
          <MailingListForm />
        </ContentBox>
        <ContentBox>
          <h2>Send us an email</h2>
          <p style={{ padding: '0 30px', textAlign: 'center' }}>
            Start the conversation. Jennifer typically responds within 24 hours.
          </p>
          <EmailForm pageEmbeddedIn="contact" />
        </ContentBox>
      </InfoPage>
    );
  }
}

export default Exhibitions;
